import { Auth } from '@aws-amplify/auth';
import { IoTClient, ListThingsCommand, ListThingsInThingGroupCommand, DescribeThingCommand } from "@aws-sdk/client-iot";
import React, { ReactNode, useEffect } from "react";
import { useState } from "react";

function defaultState() {
  return {
    availableThings: [] as any[],
    iotTopic: null as any,
    setIotTopic: (name: string) => { },
    isAdmin: false,
  };
}

type SettingsState = ReturnType<typeof defaultState>;

function useSettingsState(): SettingsState {
  // thing could probabl ybe typed...
  const [availableThings, setAvailableThings] = useState<any[]>([]);
  const [iotTopic, setIotTopic] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const getAvailableThings = async () => {
      const session = await Auth.currentSession();
      const groups = session.getIdToken().payload["cognito:groups"];
      const credentials = await Auth.currentCredentials();
      const iotClient = new IoTClient({
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
        region: process.env.REACT_APP_AWS_REGION
      });
      if (groups?.[0] === 'act_admin') {
        setIsAdmin(true);
        // it's paginated by default but we want all, 250 is max from API.
        const listThings = new ListThingsCommand({ maxResults: 250 });
        return await iotClient.send(listThings);
      } else {
        setIsAdmin(false);
        // just to keep same interafce
        // const listThings = new ListThingsCommand({ maxResults: 250 });
        // return await iotClient.send(listThings);
        const thingsForGroups: { things: any[] } = { things: [] };
        for (const group of (groups || [])) {
          const thingsForGroupCommand = new ListThingsInThingGroupCommand({ thingGroupName: group, maxResults: 250 });
          const thingsForGroup = await iotClient.send(thingsForGroupCommand);
          for (const thing of (thingsForGroup.things || [])) {
            const describeCommand = new DescribeThingCommand({ thingName: thing });
            const describeRes = await iotClient.send(describeCommand);
            thingsForGroups.things = [...thingsForGroups.things, describeRes];
          }
        }
        return thingsForGroups;
      }
    };

    if (!availableThings.length) {
      getAvailableThings().then((res) => {
        setAvailableThings((res.things || []));
      });
    }
  }, [availableThings]);

  // the first device coincidentally is in a broken state -- no shadow with no easy fixes
  // so select the "first" (but second) item when things change
  useEffect(() => {
    const available = availableThings.length;
    if (available) {
      let toSelect = availableThings[0];
      if (available > 1) {
        toSelect = availableThings[1];
      }
      if (isAdmin) {
        toSelect = availableThings.find((thing) => thing.thingName === 'ACT-CHP-1135') || availableThings[0];
      }
      setIotTopic(toSelect);
    }

  }, [availableThings]);

  return {
    availableThings,
    iotTopic,
    setIotTopic,
    isAdmin,
  };
}

export const SettingsContext = React.createContext<SettingsState>(
  defaultState()
);

export function SettingsContextProvider({ children }: { children: ReactNode }) {
  const settingsState = useSettingsState();
  return (
    <SettingsContext.Provider value={settingsState}>
      {children}
    </SettingsContext.Provider>
  );
}
